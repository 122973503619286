@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root,
.page {
  height: 100%;
  font-family: 'Roboto', sans-serif;
  margin: 0px;
  padding: 0px;
}

.page-content {
  margin-top: 60px; /* Height of ResponsiveAppBar + 10px */
  padding-bottom: 56px; /* Height of BottomNav */
  padding-left: 15px;
  padding-right: 15px;
}

.page-footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 10;
}

