@import url('https://fonts.googleapis.com/css?family=Roboto');

html,
body,
#root,
.page {
  height: 100%;
  font-family: 'Roboto', sans-serif;
  margin: 0px;
  padding: 0px;
}

.page-content {
  margin-top: 60px; /* Height of ResponsiveAppBar + 10px */
  padding-bottom: 56px; /* Height of BottomNav */
  padding-left: 15px;
  padding-right: 15px;
}

.page-footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 10;
}
